<template>
  <v-container>
    <h1>{{ verb }} Question</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.question_pack_id"
              :loading="questionPackLoading"
              :items="questionPacks"
              :rules="rules.questionPack"
              :search-input.sync="search"
              label="Question Pack"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            />
            <v-text-field
              v-if="dataId"
              v-model="form.position"
              :disabled="loading"
              :rules="rules.position"
              label="Position"
              type="number"
              required
            ></v-text-field>
            <vue-editor
              v-model="form.question"
              @image-added="handleImageAdded"
              useCustomImageHandler
              :editor-toolbar="customToolbar"
              placeholder="Insert question ..."
            />
            <v-row v-for="(answerItem, index) in form.answers" :key="index">
              <v-col lg="8" sm="11" cols="10">
                <vue-editor
                  v-model="answerItem.answer"
                  @image-added="handleImageAdded"
                  useCustomImageHandler
                  :editor-toolbar="customToolbar"
                  :placeholder="`Insert answer ${index + 1}`"
                  class="mt-4"
                />
              </v-col>
              <v-col lg="1" sm="1" cols="2" class="mx-auto">
                <v-radio-group
                  @change="updateCorrectAnswer"
                  v-model="correctAnswerIndex"
                  required
                >
                  <template v-slot:label>
                    <div>Correct</div>
                  </template>
                  <v-radio :value="index" />
                </v-radio-group>
              </v-col>
              <v-col lg="3" class="ma-lg-auto mt-n9">
                <v-btn
                  @click="addAnswerField"
                  v-if="answersLength - 1 === index"
                  color="primary"
                  class="ma-1"
                  depressed
                  >Add More</v-btn
                >
                <v-btn
                  @click="removeAnswerField(index)"
                  color="error"
                  class="ma-1"
                  depressed
                >
                  Remove
                </v-btn>
              </v-col>
            </v-row>
            <h3 class="my-2">Explanation</h3>
            <vue-editor
              v-model="form.explanation.explanation"
              @image-added="handleImageAdded"
              useCustomImageHandler
              :editor-toolbar="customToolbar"
              placeholder="Insert explanation ..."
            />
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-8"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteAnswer"
            :loading="dialogLoading"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="css" scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>

<script>
import katex from "katex";
import { VueEditor, Quill } from "vue2-editor";
import { mapActions } from "vuex";
import Answer from "@/services/answer";
import Question from "@/services/question";
import QuestionPack from "@/services/question-pack";
import S3 from "@/services/s3";
import "katex/dist/katex.min.css";
const BlockEmbed = Quill.import("blots/block/embed");
class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute(
      "style",
      "max-width: 100%; height: auto; display: block; margin: auto;"
    );
    return node;
  }
  static value(node) {
    return {
      url: node.getAttribute("src")
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);
Quill.register(Quill.import("attributors/attribute/direction"), true);
Quill.register(Quill.import("attributors/class/align"), true);
Quill.register(Quill.import("attributors/class/background"), true);
Quill.register(Quill.import("attributors/class/color"), true);
Quill.register(Quill.import("attributors/class/direction"), true);
Quill.register(Quill.import("attributors/class/font"), true);
Quill.register(Quill.import("attributors/class/size"), true);
Quill.register(Quill.import("attributors/style/align"), true);
Quill.register(Quill.import("attributors/style/background"), true);
Quill.register(Quill.import("attributors/style/color"), true);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/font"), true);
Quill.register(Quill.import("attributors/style/size"), true);

export default {
  name: "Question.Form",
  components: {
    VueEditor
  },
  data() {
    return {
      valid: false,
      form: {
        question: "",
        question_pack_id: "",
        question_type: "single_option",
        position: 0,
        answers: [{ answer: "", is_correct: false }],
        explanation: { explanation: "" }
      },
      rules: {
        questionPack: [v => !!v || "Question pack is required"],
        position: [
          v => !!v || "Position is required",
          v =>
            Number.isInteger(Number.parseFloat(v)) ||
            "Position must be integer",
          v =>
            (parseInt(v) >= 1 && parseInt(v) <= 999) ||
            "Position must be greater than 0"
        ]
      },
      search: "",
      correctAnswerIndex: null,
      questionPacks: [],
      questionPackLoading: false,
      loading: false,
      dialog: false,
      dialogLoading: false,
      selectedIndex: 0,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["image"],
        ["formula", "clean"]
      ]
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    },
    answersLength() {
      return this.form.answers.length;
    }
  },
  mounted() {
    window.katex = katex;
  },
  watch: {
    search(val) {
      val &&
        val !== this.form.question_pack_id &&
        this.searchQuestionPacks(val);
    }
  },
  async created() {
    this.setNavbarTitle("Manage Question");
    if (this.dataId) {
      const question = await Question.find(this.dataId);
      const {
        question: questionText,
        questionPack,
        answers,
        explanation,
        position
      } = question.data.data;
      this.questionPacks.push(questionPack);
      this.form = {
        question_type: "single_option",
        question: questionText,
        position,
        question_pack_id: questionPack.id,
        answers: answers.map(({ isCorrect, ...rest }) => ({
          is_correct: isCorrect,
          ...rest
        })),
        explanation: {
          id: explanation === null ? null : explanation.id,
          explanation: explanation === null ? null : explanation.explanation
        }
      };
      this.correctAnswerIndex = answers.findIndex(({ isCorrect }) => isCorrect);
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchQuestionPacks(query) {
      this.questionPackLoading = true;
      const questionPacks = await QuestionPack.search(query);
      this.questionPacks = questionPacks.data.data.questionPacks;
      this.questionPackLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (
          this.correctAnswerIndex === null ||
          this.correctAnswerIndex === -1
        ) {
          this.$snackbar.dismiss();
          return this.$snackbar.showMessage({
            content: "Choose at least one correct answer",
            variant: "warning"
          });
        }

        this.loading = true;
        try {
          if (this.dataId) {
            await Question.update(this.dataId, this.form);
          } else {
            await Question.create(this.form);
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Question.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    },
    addAnswerField() {
      this.form.answers.push({
        answer: "",
        is_correct: false,
        ...(this.dataId && { id: null })
      });
    },
    removeAnswerField(index) {
      if (this.dataId) {
        this.dialog = true;
        this.selectedIndex = index;
      } else {
        this.form.answers.splice(index, 1);
        if (this.form.answers.length === 0) {
          this.correctAnswerIndex = null;
          this.addAnswerField();
        }
      }
    },
    updateCorrectAnswer() {
      this.form.answers.forEach(answer => (answer.is_correct = false));
      this.form.answers[this.correctAnswerIndex].is_correct = true;
    },
    async deleteAnswer() {
      this.dialogLoading = true;
      const answerId = this.form.answers[this.selectedIndex].id;
      await Answer.delete(answerId);
      this.dialog = false;

      this.form.answers.splice(this.selectedIndex, 1);
      if (this.form.answers.length === 0) {
        this.correctAnswerIndex = null;
        this.addAnswerField();
      }
      this.dialogLoading = false;
    },
    async handleImageAdded(file, Editor, cursorLocation) {
      const s3Credentials = await S3.getCredentials({
        directory: "questions",
        filename: file.name,
        content_type: file.type
      });
      const formData = new FormData();
      for (const [key, value] of Object.entries(s3Credentials.data.params)) {
        formData.append(key, value);
      }
      formData.append("file", file);
      const s3Upload = await S3.upload(formData);
      const parser = new DOMParser();
      const parseXml = parser.parseFromString(s3Upload.data, "text/xml");
      const imageUrl = parseXml
        .getElementsByTagName("PostResponse")[0]
        .getElementsByTagName("Location")[0].innerHTML;
      Editor.insertEmbed(
        cursorLocation,
        "image",
        {
          url: imageUrl
        },
        Quill.sources.USER
      );
    }
  }
};
</script>
